<template>
    <div class="msg_preview">
        <h2>{{ email.subject }}</h2>
        <div class="sender_info">
            <img src="@/assets/images/default-avatar.svg" alt="">
            <div class="info">
                <h5>{{ user.businessname ? user.businessname : 'Thrive Coach'}}</h5>
                <h6><span>To:</span>{{ user.full_name }}</h6>
            </div>
        </div>
        <div class="email-logo" v-if="email.show_email_logo">
            <img :src="email.email_logo ? email.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
        </div>
        <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(email.email)"></div>
        <div v-html="generateSignature(email.is_signature, email.signature_id)"></div>
    </div>
    <div class="msgFooter text-center mt-2 mb-4">
        <p>
            <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
            </template>
            <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
        </p>
        <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
        <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        Name: 'Notification Email Preview',

        props: {
            email: Object,
        },

        emit: ['update:modelValue'],

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                whiteLabel: state => state.authModule.whiteLabel,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },
    }
</script>

<style scoped>
    .dashboard {
        background: #f5f5f5;
        padding: 30px;
        align-items: center;
        text-align: left;
    }

    .tab .dashboard {
        padding: 30px;
        background: #fafafb;
    }

    .cell .dashboard {
        padding: 15px;
        background: #fafafb;
    }

    .msg_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin: 50px auto 20px auto;
        position: relative;
    }
    .tab .msg_preview{
        margin: 30px 20px 20px 20px;
        width: calc(100% - 40px);
    }
    .cell .msg_preview{
        margin: 15px;
        width: calc(100% - 30px);
    }

    .content_preview {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: 100%;
        margin: 20px 0;
        position: relative;
        border-radius: 10px;
    }

    .msg_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .msg_preview {
        padding: 20px;
    }

    .cell .content_preview {
        padding: 15px 20px;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    :deep(.desk .msg_preview p), :deep(.desk .content_preview p)  {
        font-size: 15px !important;
        line-height: 22px !important;
    }

    :deep(.tab .msg_preview p), :deep(.tab .content_preview p) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    :deep(.cell .msg_preview p), :deep(.cell .content_preview p) {
        font-size: 11px !important;
        line-height: 16px !important;
    }

    .desk .msg_preview h2 {
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px;
        font-weight: 500;
        color: #121525;
    }

    .tab .msg_preview h2 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }

    .tab .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }

    .cell .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }

    .msgFooter p a {
        text-decoration: none;
    }

    .preview_area .area_header .logo_wpr img {
        width: 40px
    }
</style>
